import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import api from "../../../api";
import Question from "../../../components/admin/question";
import CommonLoading from "../../../loader/common-loading";

const QuestionBank = () => {
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editableQuestion, setEditableQuestion] = useState({});
  const [onOpen, setOpen] = useState(false);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [newQuestion, setNewQuestion] = useState({
    ID: "",
    question: "",
    question_type: "",
    options: [{ value: "" }],
    difficulty: "Novice",
    answer: "",
  });
  const [loading,setLoading]=useState(true);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [totalPages, setTotalPages] = useState(1); // State for total pages
  const [limit, setLimit] = useState(10); // State for results per page
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const toast = useToast();

  const handleBackBtn = () => {
    setOpen(false);
    setEditableQuestion({});
  };

  const handleBackFetchBtn = () => {
    setOpen(false);
    setEditableQuestion({});
    fetchData();
  };

  const fetchData = async (page = 1, search = "") => {
    try {
      setLoading(true)
      const response = await api.get(`/admin/all-questions?page=${page}&limit=${limit}&search=${search}`);
      const dataTypes = await api.get(`/admin/question-types`);
      setQuestionTypes(dataTypes.data.data);
      setData(response.data.data);
      setCurrentPage(page); // Set current page from response
      setTotalPages(response.data.pagination.totalPages); // Set total pages from response
      setLoading(false)
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to load questions.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchData(1, searchQuery); // Fetch data with search query, reset page to 1
  };

  const handlePageChange = (page) => {
    fetchData(page, searchQuery);
  };

  const handleEdit = (index, question) => {
    setEditableQuestion(question);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/admin/delete-question/${id}`);
      toast({
        title: "Deleted",
        description: "The question has been deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchData(currentPage, searchQuery); // Refresh questions after deletion
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to delete the question.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };


  if(loading){
   return <CommonLoading/>
  }
  return (
    <>
      {onOpen ? (
        <Question
          handleBackBtn={handleBackBtn}
          fetchBtn={handleBackFetchBtn}
          question={editableQuestion}
        />
      ) : (
        <div>
          {/* Search Bar */}
           
        <Box mt={3} textAlign="center">
              <h1 className="font-bold text-xl">List of Questions</h1>
            </Box> 

          <Flex mb={3} mt={3} pl={8} pr={4} align="center" justify="space-between">
            <Box display={'flex'}flex="2">
              <form className="w-[100%] flex" onSubmit={handleSearchSubmit}>
                <Input
                  placeholder="Search by question name or ID"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <Button type="submit" ml={2} mr={3} colorScheme="blue">
                  Search
                </Button>
              </form>
            </Box>
            <Button colorScheme="green" onClick={() => setOpen(true)}>
              Add New Question
            </Button>
          </Flex>

          <div className="flex-1 pl-8 grid gap-10">
            {data.map((question, index) => (
              <div
                key={question._id}
                className="p-6 pt-4 border-[2px] border-solid border-[#EFEEFC] rounded-2xl flex items-start gap-6"
              >
                <div className="w-14 mt-2">
                  <img
                    src="/images/dashboard/qz-type-qs.png"
                    alt="Question type icon"
                    className="block w-full"
                  />
                </div>
                <div className="flex-1">
                  <HStack justifyContent={"space-between"} alignItems={"center"}>
                    <Flex align={"center"}>
                      <p className="font-bold">
                        Question {index + 1}. ({question.ID})
                      </p>
                    </Flex>

                    <Flex align={"center"}>
                      <p className={`text-[#ffffff] text-sm bg-[red-700] p-2 ml-2 rounded-md mr-3`}>
                        {question.difficulty}
                      </p>

                      <Menu>
                        <MenuButton as={Button} variant={"none"}>
                          <BsThreeDots size={"22px"} />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => handleEdit(index, question)}>Edit</MenuItem>
                          <MenuItem onClick={() => handleDelete(question._id)}>Delete</MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  </HStack>

                  <p className="text-[#858494] text-sm">{question.question}</p>
                  <div className="flex flex-col gap-2 mt-4">
                    <RadioGroup className="radio_wrapper" value={question.answer}>
                      <Stack gap={"16px"}>
                        {Object.entries(question.options).map(([key, value]) => (
                          <Radio key={key} colorScheme="blue" value={key}>
                            <span className="radio_option">{key}</span>
                            <span className="flex-auto">{value}</span>
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <Flex mt={5} justify="center" align={'center'} mb={4}>
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
            >
              Previous
            </Button>
            <Box mx={4}>
              Page {currentPage} of {totalPages}
            </Box>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
};

export default QuestionBank;
