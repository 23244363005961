import React, { useEffect, useState } from 'react'
import api from '../../../api';
import { Card } from '@chakra-ui/react';
import ClientTable from '../../../components/tables/clientTables';

const BillingManagement = () => {
  const [transactions,setTransactions] = useState([])
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await api.get("/payment/transactions");
      setTransactions(data.data.data)
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="mt-1">
   
 {transactions &&     <ClientTable title={"Transactions"} details={transactions} itemsPerPage={20} />}
  </div>
  )
}

export default BillingManagement