import React from 'react';
import { Box, Button, Text, VStack, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <VStack spacing={8} justifyContent="center" alignItems="center" height="100vh">
      <Box textAlign="center">
        <Image
          src="https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
          boxSize="100px"
          alt="Not Found"
        />
        <Text fontSize="3xl" fontWeight="bold" mt={6}>
          404 - Page Not Found
        </Text>
        <Text color="gray.500" mb={6}>
          Sorry, the page you are looking for does not exist.
        </Text>
        <Button colorScheme="blue" as={RouterLink} to="/">
          Go Home
        </Button>
      </Box>
    </VStack>
  );
};

export default NotFound;
