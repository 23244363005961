import { BiListOl, BiSupport } from "react-icons/bi";
import { FaMoneyCheck, FaTasks } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdAnalytics, MdCampaign } from "react-icons/md";
import { PiSquaresFourFill } from "react-icons/pi";

export const candidateLinks = [
    { name: 'Dashboard', href: "/dashboard", icon: <PiSquaresFourFill /> },
    { name: "Task", href: "/dashboard/candidate/campaigns", icon: <FaTasks /> },
    { name: "Results", href: "/dashboard/candidate/results", icon: <MdAnalytics /> },
    { name: "Support", href: "/dashboard/candidate/support-system", icon: <BiSupport /> },
    { name: "Settings", href: "/dashboard/candidate/profile", icon: <IoSettingsSharp /> }
];

export const recruiterLinks = [
    { name: 'Dashboard', href: "/dashboard", icon: <PiSquaresFourFill /> },
    { name: "Evaluation Campaign", href: "/dashboard/recruiter/campaigns", icon: <MdCampaign /> },
    { name: "Billing Information", href: "/dashboard/recruiter/billing", icon: <FaMoneyCheck />},
    { name: "Results", href: "/dashboard/recruiter/results", icon: <MdAnalytics /> },
    { name: "Support", href: "/dashboard/recruiter/support-system", icon: <BiSupport /> },
    { name: "Settings", href: "/dashboard/recruiter/profile", icon: <IoSettingsSharp /> },
];

export const adminLinks = [
    { name: 'Dashboard', href: "/dashboard", icon: <PiSquaresFourFill /> },
    { name: "User Management", href: "/dashboard/admin/user-management", icon: <PiSquaresFourFill /> },
    { name: "Evaluation Library", href: "/dashboard/admin/evaluation-library", icon: <PiSquaresFourFill /> },
    { name: "Question Bank", href: "/dashboard/admin/question-bank", icon: <PiSquaresFourFill /> },
    { name: "Campaign List", href: "/dashboard/admin/campaigns", icon: <MdCampaign /> },
    { name: "Support Management", href: "/dashboard/admin/support-system", icon: <BiSupport /> },
    { name: "Payment Management", href: "/dashboard/admin/payment-management", icon: <PiSquaresFourFill /> },
    { name: "Feedback Hub", href: "/feedback-hub", icon: <PiSquaresFourFill /> },
    // { name: "Settings", href: "/dashboard/admin/profile", icon: <IoSettingsSharp /> },
];