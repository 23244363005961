import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  FormControl,
  Input,
  Select,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import api from "../../api";

const Question = ({ handleBackBtn, question, fetchBtn }) => {
  const toast = useToast();

  const [options] = useState(["A", "B", "C", "D"]);
  const [answers, setAnswers] = useState(["Answer 1", "Answer 2", "Answer 3", "Answer 4"]);
  const [newQuestion, setNewQuestion] = useState({
    ID: "",
    question: "",
    question_type: "",
    difficulty: "easy",
    answer: "",
  });
  const [steps] = useState(["Question Details", "Criteria", "Submit"]);
  const [progressValue, setProgressValue] = useState(0);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [errors, setErrors] = useState({});

  const fetchData = async () => {
    try {
      const dataTypes = await api.get(`/admin/question-types`);
      setQuestionTypes(dataTypes.data.data);
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to load questions.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (question) {
      setNewQuestion({
        ID: question.ID,
        question: question.question,
        question_type: question.question_type?._id,
        difficulty: question.difficulty,
        answer: question.answer,
      });
      if (question.options) {
        const keysArray = Object.values(question.options);
        setAnswers(keysArray);
      }
    }

    fetchData();
  }, []);

  const handlePrev = () => {
    if (stepIndex !== 0) {
      setStepIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setStepIndex((prevIndex) => prevIndex + 1);
    }
  };

  const validateCurrentStep = () => {
    let currentErrors = {};

    if (stepIndex === 0) {
      if (!newQuestion.question) {
        currentErrors.question = "Question field is required.";
      }
      if (!answers.every((answer) => answer)) {
        currentErrors.answers = "All options must be filled.";
      }
      if (!newQuestion.answer) {
        currentErrors.answer = "Please select an answer.";
      }
    }

    if (stepIndex === 1) {
      if (!newQuestion.ID) {
        currentErrors.ID = "ID is required.";
      }
      if (!newQuestion.question_type) {
        currentErrors.question_type = "Question type must be selected.";
      }
      if (!newQuestion.difficulty) {
        currentErrors.difficulty = "Difficulty must be selected.";
      }
    }

    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      toast({
        title: "Validation Error",
        description: "Please correct the errors before proceeding.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const handleAnswerChange = (index, newValue) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = newValue;
    setAnswers(updatedAnswers);
  };

  const handleAddQuestion = async () => {
    const mapOptionsToKeyValue = (options) => {
      const optionKeys = ["A", "B", "C", "D"];
      const optionsInKeyValueFormat = {};
      options.forEach((option, index) => {
        optionsInKeyValueFormat[optionKeys[index]] = option;
      });
      return optionsInKeyValueFormat;
    };

    const payload = {
      ID: newQuestion.ID,
      question: newQuestion.question,
      options: mapOptionsToKeyValue(answers),
      difficulty: newQuestion.difficulty,
      answer: newQuestion.answer,
      question_type: newQuestion.question_type,
    };

    try {
      question?._id
        ? await api.put(`/admin/edit-question/${question?._id}`, payload)
        : await api.post(`/admin/create-question`, payload);
      fetchBtn();
      toast({
        title: "Success",
        description: "New question added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setNewQuestion({
        ID: "",
        question: "",
        options: [{ value: "" }],
        difficulty: "Novice",
        answer: "",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to add the new question.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="h-full p-6">
      <Button mb={2} bg="rgb(53 30 153)" color="white" _active={{ bg: "red" }} _hover={{ bg: "#351e99b3" }} onClick={handleBackBtn}>
        Back
      </Button>
      <div className="w-9/12 pr-6">
        <div className="p-6 rounded-xl bg-white flex flex-wrap">
          <div className="w-4/12 pr-6 border-r border-solid border-border-color">
            <p className="uppercase text-xs font-semibold text-[#475A60] mb-6">
              STEP {stepIndex + 1}/{steps.length}
            </p>
            <Stepper className="stepper_form" index={stepIndex} orientation="vertical" gap="0">
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus complete={<StepIcon />} />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step}</StepTitle>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            <div className="flex justify-center p-6 bg-white shadow-ctm-shadow mt-10 flex-col items-center">
              <CircularProgress value={progressValue} size="100px" color="#351E99">
                <CircularProgressLabel fontSize={"18px"} fontWeight={"700"}>
                  {progressValue}%
                </CircularProgressLabel>
              </CircularProgress>
              <p className="text-base text-[#475A60] font-semibold mt-3">Progress</p>
            </div>
          </div>
          <div className="w-8/12 pl-6 grid content-between">
            <div>
              <h3 className="uppercase flex flex-col gap-2 items-start text-xs font-semibold">
                {stepIndex === 0 ? <>Question Details</> : stepIndex === 1 ? <>Set Criteria</> : <>Submit</>}
                <span className="block w-7 h-[2px] rounded-3xl bg-[#22A7F1]"></span>
              </h3>
              {stepIndex === 0 ? (
                <>
                  <div className="pt-5">
                    <p className="uppercase font-semibold text-xs text-[#8D999C] mb-2">Question</p>
                    <Textarea
                      value={newQuestion.question}
                      onChange={(e) => setNewQuestion({ ...newQuestion, question: e.target.value })}
                      name="description"
                      background={"#F9FAFA"}
                      fontSize={"14px"}
                      rows={5}
                      resize={"none"}
                      _focus={{ borderColor: "#000", boxShadow: "none" }}
                      placeholder="Enter the question"
                    />
                    {errors.question && <Text color="red.500">{errors.question}</Text>}
                  </div>

                  <Box className="pt-5">
                    <p className="uppercase font-semibold text-xs text-[#8D999C] mb-2">Options</p>
                    <Stack spacing={4}>
                      {options.map((option, index) => (
                        <Flex key={option} alignItems="center" borderRadius="md" px={4} py={2}>
                          <Box border="1px solid" borderColor={"blue.500"} borderRadius="full" width={8} height={8} display="flex" justifyContent="center" alignItems="center" mr={4}>
                            <Text fontWeight="bold">{option}</Text>
                          </Box>
                          <Input
                            value={answers[index] || ""}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            flex={1}
                            placeholder={`Enter option ${option}`}
                          />
                        </Flex>
                      ))}
                    </Stack>
                    {errors.answers && <Text color="red.500">{errors.answers}</Text>}
                  </Box>

                  <div className="pt-5">
                    <p className="uppercase font-semibold text-xs text-[#8D999C] mb-2">Answer</p>
                    <Select
                      placeholder="Select answer"
                      background={"#F9FAFA"}
                      fontSize={"14px"}
                      onChange={(e) => setNewQuestion({ ...newQuestion, answer: e.target.value })}
                      value={newQuestion.answer}
                    >
                      {options.map((opt, i) => (
                        <option key={i} value={opt}>
                          {opt}
                        </option>
                      ))}
                    </Select>
                    {errors.answer && <Text color="red.500">{errors.answer}</Text>}
                  </div>
                </>
              ) : stepIndex === 1 ? (
                <>
                  <div className="pt-5">
                    <p className="uppercase font-semibold text-xs text-[#8D999C] mb-2">ID</p>
                    <Input
                      value={newQuestion.ID}
                      onChange={(e) => setNewQuestion({ ...newQuestion, ID: e.target.value })}
                      name="description"
                      background={"#F9FAFA"}
                      fontSize={"14px"}
                      _focus={{ borderColor: "#000", boxShadow: "none" }}
                      placeholder="Enter Question ID"
                    />
                    {errors.ID && <Text color="red.500">{errors.ID}</Text>}
                  </div>

                  <Box className="pt-5">
                    <p className="uppercase font-semibold text-xs text-[#8D999C] mb-2">Question Type</p>
                    <Select
                      placeholder="Select question type"
                      background={"#F9FAFA"}
                      fontSize={"14px"}
                      onChange={(e) => setNewQuestion({ ...newQuestion, question_type: e.target.value })}
                      value={newQuestion.question_type}
                    >
                      {questionTypes?.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data.name}
                        </option>
                      ))}
                    </Select>
                    {errors.question_type && <Text color="red.500">{errors.question_type}</Text>}
                  </Box>

                  <Box className="pt-5">
                    <p className="uppercase font-semibold text-xs text-[#8D999C] mb-2">Difficulty</p>
                    <Select
                      placeholder="Select Difficulty"
                      background={"#F9FAFA"}
                      fontSize={"14px"}
                      onChange={(e) => setNewQuestion({ ...newQuestion, difficulty: e.target.value })}
                      value={newQuestion.difficulty}
                    >
                      <option value="Novice">Novice</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Expert">Expert</option>
                    </Select>
                    {errors.difficulty && <Text color="red.500">{errors.difficulty}</Text>}
                  </Box>
                </>
              ) : (
                <Text></Text>
              )}
            </div>

            <Flex mt={4} justifyContent={"end"} gap={4}>
              <Button onClick={handlePrev} isDisabled={stepIndex === 0}>
                Previous
              </Button>
              {stepIndex < 2 ? (
                <Button onClick={handleNext}>
                  Next
                </Button>
              ) : (
                <Button onClick={handleAddQuestion} bg="rgb(53 30 153)" color="white" _hover={{ bg: "#351e99b3" }}>
                  Submit
                </Button>
              )}
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
