import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { RiEqualizerFill } from "react-icons/ri";
import { dateFormatter } from "../../utils/time-formatter";
import "./table.css";

const data = [
  {
    name: "Ibrahim",
    company: "PATR",
    billing: "800 SAR",
    expDate: "12 Apr 2024",
    type: "Tier 1",
  },
  {
    name: "Alwaleed",
    company: "PATR",
    billing: "800 SAR",
    expDate: "12 Apr 2024",
    type: "Tier 1",
  },
  // { name: 'Diana John', company: 'Company A', billing: '800 SAR', expDate: '12 Apr 2024', type: 'Tier 1' },
  // { name: 'Cameron William', company: 'Company B', billing: '800 SAR', expDate: '12 Apr 2024', type: 'Tier 1' },
  // { name: 'Jane Cooper', company: 'Company C', billing: '2000 SAR', expDate: '05 Dec 2025', type: 'Tier 2' },
  // { name: 'John Don', company: 'Company D', billing: '3500 SAR', expDate: '29 Oct 2028', type: 'Tier 3' },
  // { name: 'William', company: 'Company E', billing: '3500 SAR', expDate: '15 Oct 2028', type: 'Tier 3' },
  // { name: 'Cameron William', company: 'Company B', billing: '800 SAR', expDate: '12 Apr 2024', type: 'Tier 1' },
  // { name: 'Jane Cooper', company: 'Company C', billing: '2000 SAR', expDate: '05 Dec 2025', type: 'Tier 2' },
  // { name: 'John Don', company: 'Company D', billing: '3500 SAR', expDate: '29 Oct 2028', type: 'Tier 3' },
  // { name: 'Diana John', company: 'Company A', billing: '800 SAR', expDate: '12 Apr 2024', type: 'Tier 1' },
  // { name: 'Cameron William', company: 'Company B', billing: '800 SAR', expDate: '12 Apr 2024', type: 'Tier 1' },
  // { name: 'Jane Cooper', company: 'Company C', billing: '2000 SAR', expDate: '05 Dec 2025', type: 'Tier 2' },
  // { name: 'John Don', company: 'Company D', billing: '3500 SAR', expDate: '29 Oct 2028', type: 'Tier 3' },
  // { name: 'William', company: 'Company E', billing: '3500 SAR', expDate: '15 Oct 2028', type: 'Tier 3' },
  // { name: 'Cameron William', company: 'Company B', billing: '800 SAR', expDate: '12 Apr 2024', type: 'Tier 1' },
  // { name: 'Jane Cooper', company: 'Company C', billing: '2000 SAR', expDate: '05 Dec 2025', type: 'Tier 2' },
  // { name: 'John Don', company: 'Company D', billing: '3500 SAR', expDate: '29 Oct 2028', type: 'Tier 3' }
  // add more data as needed
];

const types = {
  "success": "green",
  "failed": "purple",
  "Tier 3": "gray",
};

const ClientTable = ({ title, details, itemsPerPage }) => {
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState(details);
  const [selectedDate, setSelectedDate] = useState("");
  const [filters, setFilters] = useState({ status: "" });
  const [loading, setLoading] = useState(true);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      applyFilters();
      setLoading(false);
    }, 500);
  }, [details, selectedDate, filters]);

  const applyFilters = () => {
    let data = [...details];

    // Filter by date
    if (selectedDate) {
      const selectedDateObj = new Date(selectedDate);
      data = data.filter((item) => {
        const itemDate = new Date(item.Date);
        return itemDate >= selectedDateObj && itemDate <= new Date();
      });
    }

    // Filter by status
    if (filters.status) {
      data = data.filter(
        (item) => item.Status.toLowerCase() === filters.status.toLowerCase()
      );
    }

    setFilteredData(data);
    setPage(1); // Reset to first page when filters are applied
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setFilters({ ...filters, status: e.target.value });
  };

  const resetFilters = () => {
    setSelectedDate("");
    setFilters({ status: "" });
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const paginatedData = filteredData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const renderPaginationButtons = () => {
    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          variant={page === i ? "solid" : "outline"}
          className={page === i ? "Active" : ""}
          mx={1}
        >
          {i}
        </Button>
      );
    }
    return buttons.slice(Math.max(page - 2, 0), Math.min(page + 1, totalPages));
  };

  const itemsRangeStart = (page - 1) * itemsPerPage + 1;
  const itemsRangeEnd = Math.min(page * itemsPerPage, filteredData.length);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1) || "";
  }

  
  function formatSubscriptionTier(tier) {
    const parts = tier?.split("_");
    const formatted = parts
      .map((part) => part.charAt(0).toUpperCase() + part?.slice(1))
      .join(" ");

    return formatted;
  }

  return (
    <Box className="db_table" p={0}>
      <HStack
        justifyContent={"space-between"}
        alignItems={"center"}
        p="24px"
        className="border-b border-solid border-border-color"
      >
        <p className="text-xl leading-7 font-semibold font-public-san">
          {title}
        </p>
        <HStack>
          <Button
            borderRadius={"10px"}
            px={"16px"}
            width={"auto"}
            minW={"auto"}
            className="border-border-color !text-darkgrey !font-medium"
            leftIcon={<RiEqualizerFill />}
            variant="outline"
          >
            {" "}
            Filters
          </Button>
        </HStack>
      </HStack>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Transaction ID</Th>
            <Th>Customer Name</Th>
            <Th>Tier</Th>
            <Th>Payment Method</Th>
            <Th>Price</Th>
            <Th className="!text-right">Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading
            ? Array.from({ length: details.length }).map((_, index) => (
                <Tr key={index}>
                  <Td>
                    <Skeleton height="20px" />
                  </Td>
                  <Td>
                    <Skeleton height="20px" />
                  </Td>
                  <Td>
                    <Skeleton height="20px" />
                  </Td>
                  <Td>
                    <Skeleton height="20px" />
                  </Td>

                  <Td>
                    <SkeletonText noOfLines={1} skeletonHeight="20px" />
                    <Skeleton height="8px" borderRadius="30px" />
                  </Td>
                  <Td textAlign={"right"}>
                    <Skeleton height="20px" />
                  </Td>
                </Tr>
              ))
            : paginatedData.map((item, index) => (
                <Tr key={index}>  
                <Td>{item.transaction_id}</Td>
                  <Td>{item.customer_name}</Td>
                  <Td>{item.type && formatSubscriptionTier(item.type)}</Td>
                  <Td>
                    {item.payment_method}
                    <div className="text-sm text-gray-500">{item.currency_type}</div>
                  </Td>
                
                  <Td>{item.price}</Td>
                  <Td className="!text-right">
                    <span
                      className={`status status-${types[item.type]}`}
                    >
                      {item.status}
                    </span>
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
      <Flex
        justifyContent={"space-between"}
        py="16px"
        px="24px"
        alignItems={"center"}
      >
        <div className="text-sm font-medium leading-5 text-darkgrey">
          Showing {itemsRangeStart}-{itemsRangeEnd} of {data.length}
        </div>
        <HStack justifyContent="center" className="pagination-wrapper">
          <IconButton
            className="arrow-btn"
            variant={"outline"}
            icon={<FiChevronLeft />}
            onClick={() => handlePageChange(page - 1)}
            isDisabled={page === 1}
          />
          {renderPaginationButtons()}
          <IconButton
            className="arrow-btn"
            variant={"outline"}
            icon={<FiChevronRight />}
            onClick={() => handlePageChange(page + 1)}
            isDisabled={page === totalPages}
          />
        </HStack>
      </Flex>
    </Box>
  );
};

export default ClientTable;
