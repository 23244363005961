import { adminLinks, candidateLinks, recruiterLinks } from "../components/dashboard-layout/links";

const extractPathTitles = (links) => {
  return links.reduce((acc, link) => {
    const path = link.href;
    acc[path] = `${link.name} | PATR`;
    return acc;
  }, {});
};

const candidateTitles = extractPathTitles(candidateLinks);
const recruiterTitles = extractPathTitles(recruiterLinks);
const adminTitles = extractPathTitles(adminLinks);

const dynamicTitleMap = {
  ...candidateTitles,
  ...recruiterTitles,
  ...adminTitles,
  "/candidate/login": "Candidate Sign In",
  "/recruiter/login": "Recruiter Sign In",
  "/admin/login": "Admin Sign In",
  "/candidate/signup": "Candidate Sign Up",
  "/recruiter/signup": "Recruiter Sign Up",
  "/admin/signup": "Admin Sign Up",
  "/profile": "User Profile",
};

console.log(dynamicTitleMap)

/**
 * Returns the title of a page based on its route path.
 * @param {string} path - The path of the page route.
 * @returns {string} The title of the page with the site name appended.
 */

export const getTitleFromRoute = (path) => {
  if (dynamicTitleMap[path]) {
    return dynamicTitleMap[path];
  }

  const rolePaths = ["candidate", "recruiter", "admin"];
  const roleRegex = new RegExp(`^/dashboard/(${rolePaths.join('|')})/(.*)$`);
  const match = path.match(roleRegex);

  if (match) {
    const role = match[1];
    const subPath = match[2];
    
    if (subPath === "profile") {
      return `${role.charAt(0).toUpperCase() + role.slice(1)} Profile | PATR`;
    } else if (subPath === "login") {
      return `${role.charAt(0).toUpperCase() + role.slice(1)} Sign In | PATR`;
    } else if (subPath === "signup") {
      return `${role.charAt(0).toUpperCase() + role.slice(1)} Sign Up | PATR`;
    }
  }

  return "PATR";
};