import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import api from "../../api";
import { useDispatch } from "react-redux";
import { updateUserInfoSuccess } from "../../redux/reducers/user";

export const EditProfile = ({
  name: realName,
  bio: realBio,
  picture: realPicture,
  role,
  email,
  phoneNumber,
  jobTitle,
  company_name
}) => {
  const [load,setLoad]=useState(false);
  const [name, setName] = useState(realName);
  const [bio, setBio] = useState(realBio);
  const [jobName, setJobName] = useState(jobTitle);
  const [company, setCompany] = useState(company_name);
  const [phone, setPhone] = useState(phoneNumber);
  const [picture, setPicture] = useState();
  const dispatch = useDispatch();
  const toast = useToast();

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setPicture(file)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoad(true)
        const formData = new FormData();
      formData.append('name', name);
      formData.append('bio', bio);
      formData.append('phoneNumber', phone);
      if(role==="recruiter"){
        formData.append('company_name', company);
      }else{
        formData.append('jobTitle', jobName);
      }
      if (picture) {
        formData.append('picture', picture);
      }

      const response = await api.put(`/user/${role}/update-profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(updateUserInfoSuccess(response.data.data));
      setLoad(false)
      toast({
        title: "Success",
        description: response.data.message || "Updated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      console.log("Profile updated successfully", response.data);
    } catch (error) {
      setLoad(false)
      console.error("Error updating profile", error);
    }
  };
  return (
    <>
      <div className="">
        <h3 className="p-6 font-public-san text-xl font-medium border-b border-solid border-border-color">
          Edit Profile
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <Box p={5} mt={"24px"}>
          <FormControl>
            <FormLabel
              fontSize={"14px"}
              m="0"
              mb="4px"
              className="text-[#727A90]"
            >
              Photo
            </FormLabel>
            <div className="inline-flex items-center gap-5">
              <div className="w-16 h-16">
              
                <Avatar
                  width={"100%"}
                  height={"100%"}
                  className="w-full h-full"
                  name={name}
                  src={picture ? URL.createObjectURL(picture):realPicture}
                /> 
              </div>
              <label
                className="flex gap-2 text-darkBlue font-bold cursor-pointer"
                htmlFor="profile_img"
              >
                <input
                  type="file"
                  id="profile_img"
                  className="hidden"
                 accept="image/*"
                  onChange={handlePictureChange}
                />
                <span className="text-xl">
                  <MdEdit />
                </span>{" "}
                <span className="underline">Edit</span>
              </label>
            </div>
          </FormControl>
          <FormControl className="mt-6">
            <FormLabel
              fontSize={"14px"}
              m="0"
              mb="4px"
              className="text-[#727A90]"
            >
              Name
            </FormLabel>
            <Input
              type="text"
              name="Name"
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
              bgColor={"#fff"}
              _focusVisible={{
                borderColor: "#000",
                boxShadow: "none",
              }}
              borderRadius={"10px"}
              className="h-10"
              px="12px"
              py="10px"
              fontSize={"14px"}
              placeholder="Name"
            />
          </FormControl>

          <FormControl className="mt-6">
            <FormLabel
              fontSize={"14px"}
              m="0"
              mb="4px"
              className="text-[#727A90]"
            >
             {role==="recruiter"?"Company Name":"Job Title"}
            </FormLabel>
            <Input
              type="text"
              name={role==="recruiter"?"company_name":"job_title"}
              value={role==="recruiter"?company:jobName || ''}
              onChange={(e) => role==="recruiter"?setCompany(e.target.value):setJobName(e.target.value)}
              bgColor={"#fff"}
              _focusVisible={{
                borderColor: "#000",
                boxShadow: "none",
              }}
              borderRadius={"10px"}
              className="h-10"
              px="12px"
              py="10px"
              fontSize={"14px"}
              placeholder={`Your ${role==="recruiter"?"company name":"job title"}. . .`}
            />
          </FormControl>

          <FormControl className="mt-6">
            <FormLabel
              fontSize={"14px"}
              m="0"
              mb="4px"
              className="text-[#727A90]"
            >
              Phone Number
            </FormLabel>
            <Input
              type="number"
              name="number"
              value={phone || ''}
              onChange={(e) =>setPhone(e.target.value)}
              bgColor={"#fff"}
              _focusVisible={{
                borderColor: "#000",
                boxShadow: "none",
              }}
              borderRadius={"10px"}
              className="h-10"
              px="12px"
              py="10px"
              fontSize={"14px"}
              placeholder="Your phone number. . ."
            />
          </FormControl>

          <FormControl className="mt-6">
            <FormLabel
              fontSize={"14px"}
              m="0"
              mb="4px"
              className="text-[#727A90]"
            >
              Bio
            </FormLabel>
            <Textarea
              name="Bio"
              value={bio || ''}
              onChange={(e) => setBio(e.target.value)}
              rows={10}
              bgColor={"#fff"}
              _focusVisible={{
                borderColor: "#000",
                boxShadow: "none",
              }}
              borderRadius={"10px"}
              className=""
              px="12px"
              py="10px"
              fontSize={"14px"}
              placeholder="Tell us about yourself..."
            />
          </FormControl>

          <div className="flex justify-end">
            <Button
              type="submit"
              gap={"4px"}
              fontWeight={"500"}
              mt={4}
              fontSize={"14px"}
              bgColor={"#351E99"}
              _hover={{
                bgColor: "#351E99fa",
                color: "#fff",
              }}
              color={"#fff"}
              isLoading={load}

            >
              <span>
                <FaSave />
              </span>{" "}
              Save Changes
            </Button>
          </div>
        </Box>
      </form>
    </>
  );
};
