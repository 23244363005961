import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Textarea,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import api from "../../../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CommonLoading from "../../../loader/common-loading";
import { useSocket } from "../../../utils/SocketContext";

const SupportDetail = () => {
  const { user } = useSelector((state) => state.user);
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [reply, setReply] = useState("");
  const [status, setStatus] = useState("open");
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const socket = useSocket();
const navigate = useNavigate()
  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ticketID = searchParams.get("ticketId");

  useEffect(()=>{
setEmail(user?.email)
  },[])

  const validateEmail = (email) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());

  useEffect(() => {
    if (ticketID) {
      fetchTicket();
    } else {
      setIsEditable(true);
    }
  }, [ticketID]);

  const fetchTicket = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/support/${ticketID}`);
      const { category, subject, email, reply, description, status } =
        data.data;
      setCategory(category);
      setSubject(subject);
      setEmail(email);
      setReply(reply || "");
      setDescription(description);
      setStatus(status);
      setIsEditable(status === "open");
    } catch (err) {
      setError("Failed to load the ticket.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!category || !subject || !description || !email) {
      return toast({
        title: "Error",
        description: "All fields are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    if (!validateEmail(email)) {
      return toast({
        title: "Error",
        description: "Please enter a valid email address",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    const payload = { category, subject, description, email };

    try {
      setLoading(true);
      if (ticketID) {
        await api.put(`/support/edit/${ticketID}`, payload);
      } else {
        await api.post("/support/create", payload);
        clearFields();
      }
      navigate(`/dashboard/${user?.role}/support-system`)

      toast({
        title: "Success",
        description: "Support ticket sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while sending the support ticket.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReplyAndStatusUpdate = async () => {
    if (!reply && !status) {
      return toast({
        title: "Error",
        description: "Reply or status must be provided",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    const payload = { reply, status, email };

    try {
      setLoading(true);
      await api.put(`/support/reply/${ticketID}`, payload);
      navigate(`/dashboard/${user?.role}/support-system`)
      toast({
        title: "Success",
        description: "Reply and status updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while updating the reply and status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const clearFields = () => {
    socket.emit("supportNotify", {
      name: user?.name,
      role: user?.role,
      message: "A Support ticket is created",
    });
    setCategory("");
    setSubject("");
    setDescription("");
    setEmail("");
    setReply("");
  };

  if (loading) return <CommonLoading />;

  return (
    <div className="p-6">
      <div className="max-w-[575px]">
        <Stack gap={"5px"} mb={"24px"}>
          <p className="text-sm font-semibold uppercase">Support Ticket</p>
          <p className="w-9 h-[2px] bg-light-blue"></p>
        </Stack>
        <div className="grid gap-5">
          {error && <p className="text-red-500">{error}</p>}

          <FormControl isDisabled={!isEditable}>
            <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
              Request category
            </FormLabel>
            <Select
              placeholder="Search category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option>Payment</option>
              <option>Software error</option>
              <option>Suggestion</option>
            </Select>
          </FormControl>

          <FormControl isDisabled={!isEditable}>
            <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
              Support case subject
            </FormLabel>
            <Select
              placeholder="Search case"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            >
              <option>Subject 1</option>
              <option>Subject 2</option>
              <option>Subject 3</option>
            </Select>
          </FormControl>

          <FormControl isDisabled={!isEditable}>
            <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
              Support Description
            </FormLabel>
            <Textarea
              fontSize={"14px"}
              placeholder="Support description text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>

          <FormControl isDisabled={!isEditable}>
            <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
              Contact Email
            </FormLabel>
            <Input
              type="email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          {(user?.role === "candidate" && status !== "open") ||
          (user?.role === "recruiter" && status !== "open") ? (
            <>
              <FormControl isDisabled>
                <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
                  Reply to Ticket
                </FormLabel>
                <Textarea
                  fontSize={"14px"}
                  placeholder="Write your reply here..."
                  value={reply}
                />
              </FormControl>
              <FormControl>
                <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
                  Ticket Status
                </FormLabel>
                <Select isReadOnly placeholder="Select status" value={status}>
                  <option value="in progress">In Progress</option>
                  <option value="closed">Closed</option>
                </Select>
              </FormControl>
            </>
          ) : null}

          {user?.role === "admin" && (
            <>
              <FormControl>
                <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
                  Reply to Ticket
                </FormLabel>
                <Textarea
                  fontSize={"14px"}
                  placeholder="Write your reply here..."
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel className="text-darkgrey !text-xs !font-semibold uppercase">
                  Update Ticket Status
                </FormLabel>
                <Select
                  placeholder="Select status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="open">Open</option>
                  <option value="in progress">In Progress</option>
                  <option value="closed">Closed</option>
                </Select>
              </FormControl>
            </>
          )}

          <Flex justifyContent={"space-between"} mt={"24px"}>
            <Link to={`/dashboard/${user?.role}/support-system`}>
              <Button bg={"#ddd"} color={"#000"} onClick={clearFields}>
                Cancel
              </Button>
            </Link>

            {user?.role === "admin" ? (
              <Button
                bg={"#351E99"}
                color={"#fff"}
                _hover={{ bg: "#351E99ab" }}
                onClick={handleReplyAndStatusUpdate}
              >
                Reply & Update
              </Button>
            ) : (
              <Button
                bg={"#351E99"}
                color={"#fff"}
                _hover={{ bg: "#351E99ab" }}
                onClick={handleSubmit}
                isDisabled={!isEditable}
              >
                {ticketID ? "Update" : "Submit"}
              </Button>
            )}
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default SupportDetail;
