import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Text, Button, Center } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { loadUser } from "../redux/actions/user";

const PaymentSuccess = ({title,desc,color}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Dispatch your event here
    dispatch(loadUser());

    const timeout = setTimeout(() => {
      navigate("/dashboard/recruiter/billing");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [dispatch, navigate]);

  return (
    <Center h="100vh">
      <Box textAlign="center" py={10} px={6}>
        <Text fontSize="5xl" fontWeight="bold" color={color}>
          {title}
        </Text>
        <Text fontSize="lg" mt={4} mb={6} color="gray.500">
          {desc}
        </Text>
        <Button
          colorScheme="green"
          onClick={() => navigate("/dashboard/recruiter/billing")}
        >
          Go to Next Page
        </Button>
      </Box>
    </Center>
  );
};

export default PaymentSuccess;
