import api from "../../api";
import {
  LoadUserFail,
  LoadUserRequest,
  LoadUserSuccess,
  updateUserInfoFailed,
  updateUserInfoRequest,
  updateUserInfoSuccess
} from "../reducers/user";
// load user
export const loadUser = (role) => async (dispatch) => {
  try {
    dispatch(LoadUserRequest());
    const { data } = await api.get(`/user/${role}/getuser`);
    console.log(data, "DATA GOT BY USER");
    dispatch(LoadUserSuccess(data?.user));
  } catch (error) {
    console.log(error);
    dispatch(LoadUserFail(error.response.data.message));
  }
};

// User update information
export const updateUserInformation = (form) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(updateUserInfoRequest());

      const { data } = await api.put(`/user/recruiter/update`, { ...form });

      dispatch(updateUserInfoSuccess(data.data));
      resolve(data.user);
    } catch (error) {
      dispatch(updateUserInfoFailed());
      reject(error);
    }
  });
};

// get all users --- admin
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersRequest",
    });

    const { data } = await api.get(`/user/admin-all-users`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllUsersSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersFailed",
      payload: error.response.data.message,
    });
  }
};

export const LogoutUserT = (toast,user,picture) => async (dispatch) => {
  try {
    dispatch({
      type: "LoadUserRequest",
    });
    await api.post(`/user/logout`,{user,picture}, {
      withCredentials: true,
    });
    localStorage.removeItem("user");
    dispatch({
      type: "LogoutUser",
    });
    if(toast){
       toast({
      title: "Success",
      description: "You're logged out.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    }
   

    window.location.replace("/");

  } catch (error) {
    const message = error?.response?.data?.message || "Something went wrong!"
    if(toast){
    toast({
      title: "Error",
      description: message,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }
    dispatch(LoadUserFail(message))
  }
};
