import { Box, Button, Stack } from "@chakra-ui/react";
import { motion } from 'framer-motion';
import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/score.css";

const MotionBox = motion(Box);

const glitterVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: [0, 1, 0],
    y: ["0%", "100%"],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};
const Score =()=>{
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
  
    const score = queryParams.get("score");
    return(
        <>
            <div className="min-h-screen flex flex-col quest_section justify-center items-center">
                <div className="flex justify-between items-center bg-[#4120C8]/10 py-5 px-6 backdrop-blur-[8px]">
                    <div className="flex-1 flex justify-start">
                        <Link to=''>
                            <img src="/images/landing-page/logo.png" alt="PATR" className="block h-10" />
                        </Link>
                    </div>
                    <div className="flex-1 text-right">
                        <span className="text-xl text-white inline-flex"><IoCloseSharp /></span>
                    </div>
                </div>
                <div className="w-full text-center">
                    <Stack className="text-white" gap={'12px'} alignItems={'center'} justifyContent={'center'}>
                        <h3 className="text-[44px] leading-[52px] font-extrabold">Congratulations!</h3>
                        <p className="text-xl leading-7">You passed the certification</p>
                    </Stack>
                    <div className="score_wrapper">
                        <div className="score_wrapper_inner">
                            <div className="score_wrapper_inner--content">
                                 <p className="font-bold text-2xl leading-8 mb-3">Your total score is</p>
                                 <h2 className="text-[100px] leading-[100px] font-black mb-3">{score}</h2>
                                 <p className="text-xl leading-5">Out of: 15</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mt-14">
                     <Link to="/dashboard/candidate/results">  <Button
                        bg={'#351E99'}
                        color={'#fff'}
                        py={'12px'}
                        px={'16px'}
                        borderRadius={'8px'}
                        _hover={{
                            bg:'#351E99ab'
                        }}
                        >
                            Go to Dashboard
                        </Button></Link>
                    </div>
                </div>
                {Array.from({ length: 20 }).map((_, index) => (
        <MotionBox
          key={index}
          position="absolute"
          top={`${Math.random() * 100}%`}
          left={`${Math.random() * 100}%`}
          width="10px"
          height="10px"
          bg="red.300"
          borderRadius="20px"
          initial="hidden"
          animate="visible"
          variants={glitterVariants}
        />
      ))}
            </div>
        </>
    )
}

export default Score;
