import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    logs: [],
    error: null
};

const logSlice = createSlice({
    name: "logs",
    initialState,
    reducers: {
        // Loading user
        LoadLogs(state) {
            state.loading = true;
        },
        LoadLogsSuccess(state, action) {
            state.loading = false;
            state.logs = action.payload;
        },
        LoadLogsFail(state, action) {
            state.loading = false;
            state.error = action.payload;
        },


        // Clear errors
        clearErrors(state) {
            state.error = null;
        },
    },
});

// Export actions
export const {
    LoadLogsSuccess, 
    LoadLogsFail,
    LoadLogs,
    clearErrors,
} = logSlice.actions;

// Export reducer
export default logSlice.reducer;
