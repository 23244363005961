import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  loading: false,
  user: null,
  users:[],
  error: null,
  successMessage: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Loading user
    LoadUserRequest(state) {
      state.loading = true;
    },
    LoadUserSuccess(state, action) {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload;
    },
    LoadUserFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    LogoutUser(state) {
      state.isAuthenticated = false;
      state.loading = false;
      state.user = null;
      state.error = null;
      state.successMessage = "";
    },

    // Updating user information
    updateUserInfoRequest(state) {
      state.loading = true;
    },
    updateUserInfoSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    updateUserInfoFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

   

    // Admin - Get all users
    getAllUsersRequest(state) {
      state.loading = true;
    },
    getAllUsersSuccess(state, action) {
      state.loading = false;
      state.users = action.payload;
    },
    getAllUsersFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // Clear errors
    clearErrors(state) {
      state.error = null;
    },
  },
});

// Export actions
export const {
  LoadUserRequest,
  LoadUserSuccess,
  LoadUserFail,
  LogoutUser,
  updateUserInfoRequest,
  updateUserInfoSuccess,
  updateUserInfoFailed,
  getAllUsersRequest,
  getAllUsersSuccess,
  getAllUsersFailed,
  clearErrors,
} = userSlice.actions;

// Export reducer
export default userSlice.reducer;
