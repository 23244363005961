import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Store from "./redux/store";
import { Provider } from "react-redux";
import { SocketProvider } from "./utils/SocketContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
        <ChakraProvider>
            <BrowserRouter>
                <Provider store={Store}>
                    <SocketProvider>
                        <App />
                    </SocketProvider>
                </Provider>
            </BrowserRouter>
        </ChakraProvider>
    </GoogleOAuthProvider>
);
reportWebVitals();
