import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaLock, FaSave } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import api from '../../api';
import { updateUserInfoSuccess } from '../../redux/reducers/user';
  
  // Validation schema
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Must contain number (0-9), special character (%,$), uppercase and lowercase letters'
      )
      .required('New Password is required')
  });
  
  export const EditPassword = ({ password, role }) => {
    const dispatch = useDispatch();
    
    const lockIconColor = useColorModeValue('gray.500', 'gray.300');
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'gray.200');
    const toast = useToast();
  
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
  
    const handleToggleOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleToggleNewPassword = () => setShowNewPassword(!showNewPassword);
  
    const handleSubmit = async (values, actions) => {
      try {
        const { data } = await api.patch(`/user/${role}/update-password`, {
          old_password: values.old_password,
          password: values.new_password
        });
        console.log(data);
        dispatch(updateUserInfoSuccess(data.data));
        actions.resetForm();
        toast({
          title: 'Success',
          description: data.message || 'Updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      } catch (err) {
        console.log(err);
        toast({
          title: 'Error.',
          description: err?.response?.data?.message || 'Something went wrong.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
  
      actions.setSubmitting(false);
    };
  
    return (
      <>
        <div className="">
          <h3 className="p-6 font-public-san text-xl font-medium border-b border-solid border-border-color">
            Edit Password
          </h3>
        </div>
        {!password ? (
          <Flex
            direction="column"
            align="center"
            justify="center"
            h={'50vh'}
            bg={bgColor}
            borderRadius="lg"
            p={8}
            textAlign="center"
            boxShadow="lg"
          >
            <Icon as={FaLock} w={16} h={16} color={lockIconColor} mb={4} />
            <Text mt={4} fontSize="xl" fontWeight="bold" color={textColor}>
              This account is linked with a social account
            </Text>
            <Text mt={2} fontSize="md" color="gray.500">
              (e.g., Google, LinkedIn)
            </Text>
          </Flex>
        ) : (
          <Formik
            initialValues={{ old_password: '', new_password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box p={5} mt={'24px'}>
                  <FormControl className="mt-6">
                    <FormLabel
                      fontSize={'14px'}
                      m="0"
                      mb="4px"
                      className="text-[#727A90]"
                    >
                      Old Password
                    </FormLabel>
                    <InputGroup>
                      <Field
                        as={Input}
                        type={showOldPassword ? 'text' : 'password'}
                        name="old_password"
                        bgColor={'#fff'}
                        _focusVisible={{
                          borderColor: '#000',
                          boxShadow: 'none'
                        }}
                        borderRadius={'10px'}
                        className="h-10"
                        px="12px"
                        py="10px"
                        fontSize={'14px'}
                        placeholder="Enter your old password"
                      />
                      <InputRightElement width="3rem">
                        <Button h="1.5rem" size="sm" onClick={handleToggleOldPassword}>
                          {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <ErrorMessage
                      name="old_password"
                      component="div"
                      style={{ color: 'red', fontSize: '12px' }}
                    />
                  </FormControl>
  
                  <FormControl className="mt-6">
                    <FormLabel
                      fontSize={'14px'}
                      m="0"
                      mb="4px"
                      className="text-[#727A90]"
                    >
                      Set New Password
                    </FormLabel>
                    <InputGroup>
                      <Field
                        as={Input}
                        type={showNewPassword ? 'text' : 'password'}
                        name="new_password"
                        bgColor={'#fff'}
                        _focusVisible={{
                          borderColor: '#000',
                          boxShadow: 'none'
                        }}
                        borderRadius={'10px'}
                        className="h-10"
                        px="12px"
                        py="10px"
                        fontSize={'14px'}
                        placeholder="Set your new password"
                      />
                      <InputRightElement width="3rem">
                        <Button h="1.5rem" size="sm" onClick={handleToggleNewPassword}>
                          {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <ErrorMessage
                      name="new_password"
                      component="div"
                      style={{ color: 'red', fontSize: '12px' }}
                    />
                  </FormControl>
  
                  <div className="flex justify-end">
                    <Button
                      gap={'4px'}
                      fontWeight={'500'}
                      mt={4}
                      fontSize={'14px'}
                      bgColor={'#351E99'}
                      _hover={{
                        bgColor: '#351E99fa',
                        color: '#fff'
                      }}
                      color={'#fff'}
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      <span>
                        <FaSave />
                      </span>
                      Save Changes
                    </Button>
                  </div>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  };
  