import { Avatar, Button, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaBell, FaUser } from "react-icons/fa6";
import { IoCard, IoDesktop } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { RiLock2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { EditPassword } from "../../../sections/profile/edit-password";
import { EditProfile } from "../../../sections/profile/edit-profile";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUserT } from "../../../redux/actions/user";
import { useSocket } from "../../../utils/SocketContext";
import CommonLoading from "../../../loader/common-loading";

const CandidateProfile = () => {
  const [state, setState] = useState(0);
  const [load, setLoad] = useState(false);
  const socket = useSocket();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const toast = useToast();
  const handleLogout = async () => {
    setLoad(true);
    await dispatch(LogoutUserT(toast,user?.name,user?.picture));
    setLoad(false);
  };
  useEffect(() => {
    if (socket) {
      const userId = user._id;
      const role = user?.role;
      console.log(role);
      socket.emit("addUser", userId, user?.role);
      socket.on("getUsers", (data) => {
        console.log(data, "SOCKET DATA");
      });
      socket.on("getNotification", (data) => {
        console.log(data, "SOCKET NOTIFICATION");
      });
      // Cleanup on component unmount
      return () => {
        socket.off("getUsers");
      };
    }
  }, [socket, user]);

  function BioDisplay({ bio }) {
    const formattedBio = bio.split("\n").map((line, index) => (
      <p className="text-sm text-darkgrey font-medium" key={index}>
        {line}
      </p>
    ));

    return <div>{formattedBio}</div>;
  }

  if (load) {
    return <CommonLoading />;
  }

  return (
    <>
      <div className="flex flex-wrap h-full">
        <div className="w-3/12 border-r border-solid border-border-color">
          <div className="pt-2 px-6">
            <div className="-mx-4">
              <img
                src="/images/dashboard/db-banner.png"
                alt="profile-banner"
                className="h-[148px] block w-full object-cover rounded"
              />
            </div>
            <div className="text-center pb-6 border-b border-solid border-border-color">
              <div className="-mt-20 mb-3">
                {/* <div className="w-32 h-32 rounded-full bg-[#e1e1df] mx-auto"> */}
                <Avatar
                  src={user?.picture}
                  name={user?.name}
                  alt="profile-photo"
                  size={"2xl"}
                />
                {/* </div> */}
              </div>
              <h3 className="text-xl font-semibold font-public-san">
                {user?.name}
              </h3>
              <p className="text-sm text-darkgrey font-medium mb-3">
                {user?.email}
              </p>
              {user?.bio ? (
                <BioDisplay bio={user.bio} />
              ) : (
                <p className="text-sm text-darkgrey font-medium">
                  "“Add your bio”"
                </p>
              )}
            </div>
            <div className="mt-6">
              <ul className="sidenav-list-main">
                <li className="sidenav-list" onClick={() => setState(0)}>
                  <Link
                    className={`sidenav-list-link ${state === 0 && "active"}`}
                  >
                    <span className="menu-icon !w-4">
                      <FaUser />
                    </span>
                    <span className="menu-label">Edit Profile</span>
                  </Link>
                </li>
                <li className="sidenav-list" onClick={() => setState(1)}>
                  <Link
                    className={`sidenav-list-link ${state === 1 && "active"}`}
                  >
                    <span className="menu-icon !w-4">
                      <RiLock2Fill />
                    </span>
                    <span className="menu-label">Edit Password</span>
                  </Link>
                </li>
                <li className="sidenav-list" onClick={() => setState(2)}>
                  <Link
                    className={`sidenav-list-link ${state === 2 && "active"}`}
                  >
                    <span className="menu-icon !w-4">
                      <IoCard />{" "}
                    </span>
                    <span className="menu-label">Subscription</span>
                  </Link>
                </li>
                <li className="sidenav-list" onClick={() => setState(3)}>
                  <Link
                    className={`sidenav-list-link ${state === 3 && "active"}`}
                  >
                    <span className="menu-icon !w-4">
                      <IoDesktop />
                    </span>
                    <span className="menu-label">Devices</span>
                  </Link>
                </li>
                <li className="sidenav-list" onClick={() => setState(4)}>
                  <Link
                    className={`sidenav-list-link ${state === 4 && "active"}`}
                  >
                    <span className="menu-icon !w-4">
                      <FaBell />
                    </span>
                    <span className="menu-label">Notification</span>
                  </Link>
                </li>
                <li>
                  <Button
                    className="w-full flex items gap-1 mt-6"
                    onClick={handleLogout}
                    variant="outline"
                    borderColor={"#CB0101"}
                    color={"#CB0101"}
                    _hover={{
                      bgColor: "#CB0101",
                      color: "#fff",
                    }}
                  >
                    <span className="text-xl">
                      <MdLogout />
                    </span>{" "}
                    Sign Out
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-9/12">
          {state === 0 ? (
            <EditProfile {...user} />
          ) : state === 1 ? (
            <EditPassword password={user?.password} role={user?.role} />
          ) : state === 2 ? (
            "Subsription section"
          ) :  state === 3 ? (
            "Devices"
          ) : (
            "Notification"
          )}
        </div>
      </div>
    </>
  );
};
export default CandidateProfile;
