import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    campaign: [],
    campaignDetailsLoading: false,
    campaignDetails: [],
    campaignDetailsError: null,
    error: null
};

const campaignSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {
        // Loading user
        LoadCampaigns(state) {
            state.loading = true;
        },
        LoadCampaignsSuccess(state, action) {
            console.log(action.payload)
            state.loading = false;
            state.campaign = action.payload;
        },
        LoadCampaignsFail(state, action) {
            state.loading = false;
            state.campaign = [];
            state.error = action.payload;
        },
        LoadCampaignsDetails(state) {
            state.campaignDetailsLoading = true;
        },
        LoadCampaignsDetailsSuccess(state, action) {
            state.campaignDetailsLoading = false;
            state.campaignDetails = action.payload;
        },
        LoadCampaignsDetailsFail(state, action) {
            state.campaignDetailsLoading = false;
            state.campaignDetails = [];
            state.campaignDetailsError = action.payload;
        },


        // Clear errors
        clearErrors(state) {
            state.error = null;
            state.campaignDetailsError = null;
        },
    },
});

// Export actions
export const {
    LoadCampaignsSuccess, LoadCampaigns, LoadCampaignsFail, LoadCampaignsDetails, LoadCampaignsDetailsSuccess, LoadCampaignsDetailsFail,
    clearErrors,
} = campaignSlice.actions;

// Export reducer
export default campaignSlice.reducer;
