import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user";
import logsReducer from "./reducers/logs";
import campaignReducer from "./reducers/campaigns";

const Store = configureStore({
  reducer: {
    // Reducers
    user:userReducer,
    logs:logsReducer,
    campaigns:campaignReducer,
  },
});

export default Store;
